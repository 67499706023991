<template>
        <div class="search-bar">
                <el-autocomplete @select="search" class="block" clearable :trigger-on-focus="false" :maxlength="60" @keyup.enter.native="search" v-model="form.keyword" placeholder="搜索" :fetch-suggestions="querySearch">
                        <el-select v-model="form.type" class="type-selecter" slot="prepend">
                                <el-option v-for="(val,index) in types" :key="index" :value="val.type" :label="val.label"></el-option>
                        </el-select>
                </el-autocomplete>
                <el-button class="submit-btn" type="danger" @click="search">搜索</el-button>
        </div>
</template>
<script>
export default {
        data() {
                return {
                        types:[
                                {
                                        type:'goods',
                                        label:'商品',
                                },
                                {
                                        type:'factorys',
                                        label:'厂家'
                                }
                        ],
                        form:{
                                type:'goods',
                                keyword:''
                        }
                }
        },
        methods:{
                search() {
                        this.$emit('search',this.form)
                },

                //搜索建议
                querySearch(queryString,callback) {
                        var result = []
                        for(let i=0;i<5;i++) {
                                result.push({value:queryString+',搜索建议测试'+(i+1)})
                        }
                        setTimeout(() => {
                                callback(result)
                        },200)
                }
        }
}
</script>
<style lang="less" scoped>
/deep/
.el-input-group__prepend{
        background-color: #fff;
}
        .search-bar{
                display: flex;
                padding-top: @pd-lg;
                .type-selecter{
                        width:80px;
                }
                .block{
                        flex:1;
                }
                .submit-btn{
                        margin-left: 10px;
                }
        }
</style>