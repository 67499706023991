<template>
        <div class="top-bar">
                <div class="main">
                        <el-row :gutter="20">
                                <el-col :span="12">
                                        <span class="text">
                                                欢迎!
                                                <el-divider direction="vertical"></el-divider>
                                                {{zone.label}}站
                                        </span>
                                                <el-link type="primary" :underline="false">
                                                        切换
                                                </el-link>
                                </el-col>
                                <el-col :span="12" class="text-right">
                                        <el-link class="hidden-sm-and-down" :underline="false">贵美人采购首页</el-link>

                                        <el-divider class="hidden-sm-and-down" direction="vertical"></el-divider>
                                        <el-link class="hidden-sm-and-down" :underline="false">意见反馈</el-link>

                                        <el-divider direction="vertical" class="hidden-sm-and-down"></el-divider>
                                        <el-link :underline="false">厂家中心</el-link>

                                        <el-divider direction="vertical"></el-divider>
                                        <el-link :underline="false">登陆/注册</el-link>
                                        
                                </el-col>
                        </el-row>
                </div>
        </div>
</template>
<script>
export default {
        data() {
                return {
                        zone:{
                                id:1,
                                label:'浙江'
                        }
                }
        }
}
</script>
<style lang="less" scoped>
        .el-divider__text,
        .el-link{
                font-weight: normal;
                font-size: 12px;
        }
        .top-bar{
                width:100%;
                color:@color-2;
                background-color: @bg-color;
                padding:@pd-md @pd-md;
                border-bottom: @bd;
                box-sizing: border-box;
                >.main{
                        width:100%;
                        max-width: @max-width;
                        margin:0 auto;
                        box-sizing: border-box;
                        padding: 0 @pd-md;
                        .text{
                                font-size: @size-sm;
                                margin-right: 8px;
                        }
                }
        }
</style>