<template>
  <div id="app">
    <app-header @search="onsearch" />
    <div class="pd-y container">
      <router-view :keyword="search_keyword" ref="router-view"></router-view>
    </div>
    <app-footer />
    <div class="fixed-tools">
      <el-badge :value="12" class="item">
        <el-button type="warning" circle icon="el-icon-shopping-cart-2"></el-button>
      </el-badge>
    </div>
  </div>
</template>
<script>
import AppHeader from '@/components/header/header.vue'
import AppFooter from '@/components/footer/footer.vue'
export default {
  components:{
    AppHeader,
    AppFooter
  },
  methods:{
    onsearch( val ) {
      this.search_keyword = val.keyword;
      if(this.$route.name != val.type) {
        this.$router.push({
          name:val.type
        })
      }
      this.$refs['router-view'].loadList();
    }
  },
  data() {
    return {
      search_keyword:'',
    }
  }
}
</script>
<style lang="less">
  html,body,#app{
    width:100%;
    height:100%;
    background-color: @body-bg;
    .container{
      min-height:100%!important;
    }
  }
  .fixed-tools{
    position: fixed;
    right:20px;
    bottom:40px;
    padding:10px;
    background-color: @bg-color;
    box-shadow: @shadow;
    border-radius: 100px;
  }
</style>