const Mock = require('mockjs')
const Random = Mock.Random;
Mock.setup({
        timeout:'200-500'
})

//商品列表
Mock.mock(/\/goods/,'get',( options ) => {
        console.log('mock',options)
        var list = []
        for(var i=0;i<10;i++) {
                list.push({
                        id:i+1,
                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                        has_video:1,
                        price:'998',
                        volume:23,
                        factory:{
                                id:323,
                                name:'上海XXX仪器设备有限公司'
                        }
                })
        }
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:42,
                        list:list
                }
        }
})