import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    name:'goods',
    path:'/',
    meta:{
      title:'商品'
    },
    component: () => import('./views/good-list.vue')
  },
  {
    name:'factorys',
    path:'/factorys',
    meta:{
      title:'厂家'
    },
    component: () => import('./views/factory-list.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
